import type { OperationOptions } from "@azure/core-client";
import type { HttpHeaders, Pipeline, RestError } from "@azure/core-rest-pipeline";
import { defer } from "lodash";

function getJwtAreaName() {
	let pathname = typeof window !== "undefined" ? window.location.pathname : undefined;

	if (!pathname) {
		return undefined;
	}

	if (pathname === "/") {
		return "web";
	}

	if (pathname[0] === '/') {
		pathname = pathname.slice(1);
	}

	const pathnameTokens = pathname.split('/');

	if (pathnameTokens.length === 0) {
		return undefined;
	}

	if (pathnameTokens[0] === "admin") {
		return "admin";
	}

	return "web";
}

function getEnvironmentName() {
	const hostname = typeof window !== "undefined" ? window.location.hostname : undefined;

	if (!hostname) {
		return undefined;
	}

	const hostnameTokens = hostname.split(/[^a-z]/i);

	if (hostnameTokens.length === 0) {
		return undefined;
	}

	const firstHostnameToken = hostnameTokens[0];

	if (firstHostnameToken === "reshopper") {
		return "prod";
	}

	if (hostname === "localhost") {
		return "local";
	}

	if (hostnameTokens.length === 1) {
		return undefined;
	}

	const secondHostnameToken = hostnameTokens[1];

	if (firstHostnameToken === "web") {
		return secondHostnameToken;
	}

	return undefined;
}

export function isProd() {
	return getEnvironmentName() === "prod";
}

function isProdtest() {
	return getEnvironmentName() === "prodtest";
}

export function isDevelopment() {
	const environmentName = getEnvironmentName();
	return !!environmentName && !isProd() && !isProdtest() && !isLocal();
}

function isLocal() {
	return getEnvironmentName() === "local";
}

function getDefaultBasePath() {
	const scheme = isLocal() ?
		"http:" :
		"https:";
	return `${scheme}//${getDefaultHostname()}/`;
};

function getDefaultHostname() {
	if (isProd()) {
		return "app.reshopper.com";
	}

	if (isProdtest()) {
		return "prodtest.reshopper.com";
	}

	if (isDevelopment()) {
		return `${getEnvironmentName()}.reshopper.com`;
	}

	if (isLocal()) {
		return window.location.hostname + ":5000";
	}
	throw Error("Failed to resolve default hostname");
};

export function getBasePath() {
	if (typeof sessionStorage === "undefined")
		return getDefaultBasePath();

	const storedBasePath = sessionStorage.getItem("api-base-url");
	return storedBasePath || getDefaultBasePath();
}

export async function createHttpClient() {
	const CoreRestPipeline = await import("@azure/core-client");
	const client = new CoreRestPipeline.ServiceClient();
	addMiddlewaresToPipeline(client.pipeline);

	return client;
}

export type ResponseDetails<T = unknown> = {
	data: T,
	statusCode: number | null,
	headers: HttpHeaders | null,
	rawData: string | null | undefined
};

export function addMiddlewaresToPipeline(pipeline: Pipeline) {
	pipeline.addPolicy({
		name: "allowInsecureConnections",
		sendRequest: async (request, next) => {
			request.allowInsecureConnection = true;

			const response = await next(request);
			return response;
		}
	});

	pipeline.addPolicy({
		name: "withCredentials",
		sendRequest: async (request, next) => {
			request.withCredentials = true;

			const response = await next(request);
			return response;
		}
	});

	pipeline.addPolicy({
		name: "version",
		sendRequest: async (request, next) => {
			//we always set the app version to 999.0.0 here, so that server-side version checks will always pass as "latest".
			request.headers.set("X-ReshopperVersion", `999.0.0`);

			const response = await next(request);
			return response;
		}
	});

	let isRenewing = false;

	pipeline.addPolicy({
		name: "jwtRenewal",
		sendRequest: async (request, next) => {
			const isOnAdminPath = window.location.href.indexOf("admin") !== -1;
			const area = isOnAdminPath ? "admin" : "web";
			const jwtCookiePayload = getJwtCookiePayload();
			const expiredToken = jwtCookiePayload && isJwtTokenExpired(jwtCookiePayload);

			if (expiredToken && !isRenewing) {
				isRenewing = true;
				try {
					await fetch(
						`${getBasePath()}${area}/authentication/refresh-jwt`,
						{
							method: "POST",
							credentials: "include"
						});
					isRenewing = false;
				} catch (error) {
					isRenewing = false;
					deleteJwtCookies();
					console.log("By middleware");
					window.location.href = '/';
					throw error;
				}
			}

			return await next(request);
		}
	});

	pipeline.addPolicy({
		name: "redirectToServerSpecifiedLocation",
		sendRequest: async (request, next) => {
			const response = await next(request);

			let redirectUrl = response.headers.get("Location");
			if (redirectUrl && typeof window !== "undefined") {
				const currentBaseUrl = window.location.origin.toLowerCase();
				if (redirectUrl.indexOf(currentBaseUrl) === 0)
					redirectUrl = redirectUrl.substr(currentBaseUrl.length);

				console.log("By middleware redirect");
				window.location.href = redirectUrl;

				return response;
			}

			return response;
		}
	});

	pipeline.addPolicy({
		name: "redirectToLogin",
		sendRequest: async (request, next) => {
			try {
				return await next(request);
			} catch (error) {
				const response = error as RestError;
				if ('statusCode' in response && response.statusCode === 401) {
					deleteJwtCookies();
					console.log("By middleware login redirect");
					const isOnAdminPath = window.location.href.indexOf("admin") !== -1;
					if (isOnAdminPath) {
						window.location.href = '/admin';
					}
				}
				throw error;
			}
		}
	});

	pipeline.addPolicy({
		name: "throwErrorOnUnsuccesfulStatusCode",
		sendRequest: async (request, next) => {
			const response = await next(request);

			if (!isSuccessStatusCode(response.status)) {
				throw new ApiCallError(
					response.status,
					response.headers,
					response.bodyAsText
				)
			}

			return response;
		}
	})
}

export async function WithActionResultDetails<T>(
	promise: (options: OperationOptions) => Promise<T>,
	responseInterceptionMode: "reject-on-error" | "resolve-if-successful" = "resolve-if-successful"
): Promise<ResponseDetails<T>> {
	let hasOnResponseFired = false;
	return new Promise((resolve, reject) => {
		promise({
			onResponse: response => {
				hasOnResponseFired = true;

				try {
					if (responseInterceptionMode === "resolve-if-successful") {
						resolve({
							data: response.parsedBody,
							statusCode: response.status,
							headers: response.headers,
							rawData: response.bodyAsText
						});
					}
				}
				catch (e: unknown) {
					if (responseInterceptionMode === "reject-on-error") {
						reject(e);
					}
				}
			}
		}).then(response => {
			defer(() => {
				if (!hasOnResponseFired) {
					console.warn("A request was performed but onResponse was never triggered. Perhaps you forgot to pass options in to the API call of WithActionResultDetails?");
				}

				resolve({
					data: response,
					statusCode: null,
					headers: null,
					rawData: null
				});
			});
		});
	});
}

function isSuccessStatusCode(statusCode: number) {
	return statusCode < 400;
}

export class ApiCallError extends Error {
	constructor(
		public statusCode: number,
		public headers: HttpHeaders,
		public rawData: string | null | undefined) {
		super(rawData ?? undefined);
	}
}

const encodedJwtPayloadKey = `encoded_jwt_payload.${getJwtAreaName()}.${getEnvironmentName()}`;

const getCookie = (name: string): string | null => {
	if (typeof document === 'undefined') {
		return null;
	}
	const cookies = document.cookie.split(";");
	for (let i = 0; i < cookies.length; i++) {
		const cookiePair = cookies[i].split("=");
		if (name == cookiePair[0].trim()) {
			return cookiePair[1];
		}
	}
	return null;
}

export const getJwtCookiePayload = (): JwtCookiePayload | null => {
	const encodedJwtPayload = getCookie(encodedJwtPayloadKey);

	if (!encodedJwtPayload) {
		return null;
	}
	return JSON.parse(window.atob(encodedJwtPayload));
}

export const isJwtTokenExpired = (jwtPayload: JwtCookiePayload): boolean => {
	const expiryDate = new Date(jwtPayload.exp * 1000);
	const now = new Date();

	const isTokenExpired = now.getTime() > expiryDate.getTime();
	return isTokenExpired;
}

export const hasSuperUserAccessThroughJwt = (jwtPayload: JwtCookiePayload | null): boolean => {
	if (!jwtPayload) {
		return false;
	}

	if (isJwtTokenExpired(jwtPayload)) {
		return false;
	}

	const role = jwtPayload.role;
	return role === "Superuser";
}

export const hasAdministratorAccessThroughJwt = (jwtPayload: JwtCookiePayload | null): boolean => {
	if (!jwtPayload) {
		return false;
	}

	if (isJwtTokenExpired(jwtPayload)) {
		return false;
	}

	const role = jwtPayload.role;
	return role === 'Administrator' || role === "Superuser";
}

export const hasExactlyDashboardFullAccessThroughJwt = (jwtPayload: JwtCookiePayload | null): boolean => {
	if (!jwtPayload) {
		return false;
	}

	if (isJwtTokenExpired(jwtPayload)) {
		return false;
	}

	const role = jwtPayload.role;
	return role === 'DashboardFull';
}

export const hasExactlyDashboardBasicAccessThroughJwt = (jwtPayload: JwtCookiePayload | null): boolean => {
	if (!jwtPayload) {
		return false;
	}

	if (isJwtTokenExpired(jwtPayload)) {
		return false;
	}

	const role = jwtPayload.role;
	return role === 'DashboardBasic';
}

export const getUserIdFromJwtCookiePayload = (jwtPayload: JwtCookiePayload | null): string | undefined => {
	return jwtPayload?.sub;
}

// We cannot delete the 'encoded_jwt_header_and_signature' cookie as it is HttpOnly.
// This should not be an issue since we only check for 'encoded_jwt_payload'
export const deleteJwtCookies = (): void => {
	const hostname = typeof window !== "undefined" ? window.location.hostname : undefined;
	const domain = hostname?.indexOf("localhost") !== -1 ?
		"localhost" :
		".reshopper.com";

	document.cookie = `${encodedJwtPayloadKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;
}

export interface JwtCookiePayload {
	sub: string;
	country: string;
	isPlus: string;
	role: string | string[];
	nbf: number;
	exp: number;
	iat: number;
	iss: string;
	aud: string;
}
