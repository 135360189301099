import { Web } from "@reshopper/web-client";
import { addMiddlewaresToPipeline, getBasePath } from "../../utils/swaggerClientUtils";

export function webClient() {
	const hostname = getBasePath();
	var client = new Web(null!, hostname, {
		requestContentType: "application/json; charset=utf-8",
		baseUri: hostname,
		allowInsecureConnection: true
	});
	addMiddlewaresToPipeline(client.pipeline);
	return client;
}